
import Vue from "@/extensions/Vue";
import {Options} from "vue-class-component";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import ContractMeterData from "@/http/data/contract-meter-data";
import {contractContext, meterContext, unitContext} from "@/store";
import MeterButton from "@/components/controls/MeterButton.vue";
import UnitHeader from "@/components/ui/UnitHeader.vue";
import UnitData from "@/http/data/unit-data";

import _ from "lodash";
import ContractData from "@/http/data/contract-data";

@Options({
    name: "Meter",
    components: {
        UnitHeader,
        MeterButton,
        DefaultLayout
    }
})
export default class Meters extends Vue {
    get contract(): ContractData {
        return _.find(contractContext.state.contracts, contract => contract.id === this.contractId) as ContractData;
    }

    get contractId(): string {
        return meterContext.state.selectedMeterContractId as string;
    }

    get meters(): ContractMeterData[] {
        return _.chain(meterContext.state.contractMeters)
            .filter((meter: ContractMeterData) => meter.contract_id === this.contractId && !!meter.getNextRequiredDate())
            .value();
    }

    get unit(): UnitData {
        return unitContext.getters.selectedUnit as UnitData;
    }

    async onGoBack(): Promise<void> {
        await this.$router.back();
    }
}
