
import Vue from "@/extensions/Vue";
import {Options, prop} from "vue-class-component";
import UnitData from "@/http/data/unit-data";
import ContractData from "@/http/data/contract-data";
import {contractContext} from "@/store";

class Props {
    unit = prop({
        required: true,
        validator: (value: unknown): boolean => value instanceof UnitData
    });
    contract = prop({
        required: false,
        validator: (value: unknown): boolean => value instanceof ContractData
    });
    contractId = prop({
        required: false,
        type: String
    });
}

@Options({
    name: "UnitHeader"
})
export default class UnitHeader extends Vue.with(Props) {
    declare unit: UnitData;
    declare contract: ContractData|undefined;
    declare contractId: string|undefined;

    get contractIdOrNull(): string|null {
        const contractId = this.contractId || this.contract?.id;

        return contractId ?? null;
    }

    get contractStatus(): string|null {
        if (!this.contract) return null;

        return contractContext.getters.getContractStatus(this.contract);
    }

    async viewContract(contractId: string): Promise<void> {
        await contractContext.actions.selectContractInfoId(contractId);
        await this.$router.push({name: "Contract"});
    }
}
