<template>
    <div class="w-100 meter-button" element-loading-spinner="el-icon-loading" v-loading="isLoading">
        <div class="px-2 d-flex justify-content-between">
            <div class="text-primary fw-bold">{{ capitalize(meter.meter_type) }}</div>
            <div v-if="meter.limit"><i class="fa fa-redo" /> {{ meter.limit }}</div>
        </div>
        <el-form class="w-100 d-flex flex-wrap meter-button--form px-1" :model="formData" :rules="formRules" ref="elForm" v-if="formData">
            <el-form-item class="w-50 meter-button--item mb-0" :prop="date" v-for="(value, date) in formData" :key="date">
                <div class="fw-bold font-small-3">{{ determineFormItemText(date) }}</div>
                <el-input v-model="formData[date]" @input="forceDownload=false;" :disabled="date !== nextRequiredDate">
                    <template #append>.0</template>
                </el-input>
            </el-form-item>
        </el-form>
        <div class="py-2 d-flex justify-content-center mt-2">
            <el-button type="info" size="small" @click="onUpload(false)" v-if="!forceDownload" :disabled="!meter.getNextRequiredDate()">
                Upload
            </el-button>
            <el-button type="info" size="small" @click="onUpload(true)" v-else>
                Force upload
            </el-button>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from "@/extensions/Vue";
import {Options, prop} from "vue-class-component";
import ContractMeterData from "../../http/data/contract-meter-data";
import Meter from "@/views/Meters.vue";
import {FormRulesMap} from "element-plus/es/el-form/src/form.type";
import {Rules} from "async-validator";
import {ElForm} from "element-plus";
import _ from "lodash";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {meterContext} from "@/store";

dayjs.extend(utc);

class Props {
    meter = prop({
        required: true,
        validator: (value: unknown): boolean => value instanceof ContractMeterData
    });
}

@Options({
    name: "MeterButton",
    components: {Meter}
})
export default class Meters extends Vue.with(Props) {
    declare meter: ContractMeterData;

    isLoading = false;
    forceDownload = false;

    formData = {} as Record<string, number|string|null>

    formRules = {} as FormRulesMap

    beforeMount(): void {
        this.formData = _.chain(this.meter.value_by_date)
            .mapKeys((value, date) => dayjs(date).format("YYYY-MM-DD"))
            .value();

        if (!this.nextRequiredDate) return;

        this.formRules = {
            [this.nextRequiredDate]: {
                validator: this.validateValue,
                trigger: "blur"
            }
        }
    }

    get maxDate(): string {
        return dayjs(this.meter.max_date).format("YYYY-MM-DD");
    }

    get minDate(): string {
        return dayjs(this.meter.min_date).format("YYYY-MM-DD");
    }

    get nextRequiredDate(): string|null {
        if (!this.meter.getNextRequiredDate()) return null;

        return dayjs(this.meter.getNextRequiredDate()).format("YYYY-MM-DD");
    }

    determineFormItemProp(date: string): string {
        return date === this.meter.getNextRequiredDate() ? "requiredValue" : date;
    }

    determineFormItemText(date: string): string {
        if (date === this.minDate) {
            return "Start value";
        } else if (date === this.maxDate) {
            return "End value";
        } else {
            return "Price change";
        }
    }

    isPositiveInteger(str: string): boolean {
        return /^(0|[1-9][0-9]*)$/.test(str);
    }

    async onUpload(force: boolean): Promise<void> {
        const form = this.$refs["elForm"] as typeof ElForm;

        if (!form) return ;

        await form.validate(async (isValid: boolean) => {
            if (!isValid) return;

            const meter = this.meter.clone();

            const nextRequiredValue = parseInt(this.formData[this.nextRequiredDate as string] as string);

            meter.setNextRequiredValue(nextRequiredValue);

            meter.force = force;

            this.isLoading = true;

            try {
                await meterContext.actions.postContractMeter(meter);
            } catch ($e) {
                this.forceDownload = true;
                // Do nothing
            } finally {
                this.isLoading = false;
            }
        });
    }

    validateValue(rule: Rules, value: unknown): boolean|Error|Error[] {
        if (!value) {
            return new Error("This field is required.")
        } else if (typeof value !== "string" || !this.isPositiveInteger(value)) {
            return new Error("Value must be numeric.");
        }

        const valueAsInt = parseInt(value);

        const limit = this.meter.limit ?? Number.MAX_SAFE_INTEGER;

        if (this.meter.limit && valueAsInt > limit) {
            return new Error(`Value cannot be greater than ${limit}.`);
        }

        return true;
    }
}
</script>

<style lang="scss" scoped>
@import "src/scss/variables";

.meter-button {
    &:deep {
        .el-input-group__append {
            color: white;
            background-color: $danger;
            padding-left: .5rem;
            padding-right: .5rem;
        }

        .el-icon-loading {
            font-size: 3em;
        }
    }
}

.meter-button--item {
    &:nth-child(odd) {
        padding-right: .125rem;
    }

    &:nth-child(even) {
        padding-left: .125rem;
    }
}
</style>