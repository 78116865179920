<template>
    <default-layout>
        <template v-if="meters.length > 0">
            <section>
                <unit-header :unit="unit" :contract="contract" :contract-id="contractId" />
            </section>
            <section>
                <el-alert title="Do not insert the number after the decimal." type="warning" :closable="false" />
                <meter-button class="my-3" v-for="meter in meters" :key="meter.meter_id" :meter="meter" />
            </section>
        </template>
        <template v-else>
            <h1 class="my-4 text-center">All meters are up-to-date</h1>
            <div class="d-flex justify-content-center">
                <el-button type="info" @click="onGoBack()">
                    <i class="me-2 fa fa-long-arrow-alt-left" />Go back
                </el-button>
            </div>
        </template>
    </default-layout>
</template>

<script lang="ts">
import Vue from "@/extensions/Vue";
import {Options} from "vue-class-component";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import ContractMeterData from "@/http/data/contract-meter-data";
import {contractContext, meterContext, unitContext} from "@/store";
import MeterButton from "@/components/controls/MeterButton.vue";
import UnitHeader from "@/components/ui/UnitHeader.vue";
import UnitData from "@/http/data/unit-data";

import _ from "lodash";
import ContractData from "@/http/data/contract-data";

@Options({
    name: "Meter",
    components: {
        UnitHeader,
        MeterButton,
        DefaultLayout
    }
})
export default class Meters extends Vue {
    get contract(): ContractData {
        return _.find(contractContext.state.contracts, contract => contract.id === this.contractId) as ContractData;
    }

    get contractId(): string {
        return meterContext.state.selectedMeterContractId as string;
    }

    get meters(): ContractMeterData[] {
        return _.chain(meterContext.state.contractMeters)
            .filter((meter: ContractMeterData) => meter.contract_id === this.contractId && !!meter.getNextRequiredDate())
            .value();
    }

    get unit(): UnitData {
        return unitContext.getters.selectedUnit as UnitData;
    }

    async onGoBack(): Promise<void> {
        await this.$router.back();
    }
}
</script>

<style lang="scss" scoped>
section {
    &:not(:last-child) {
        border-bottom: 2px dashed black;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
    }
}
</style>